header {
  background-image: linear-gradient(#3f7286 0%, #007aaa 20%, #2dbcf5 100%, #FFF 100%);
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(46, 41, 51, 0.5) 0px 2px 20px;
}

h1 {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: lighter;
  font-size: 4em;
}

a {
  text-decoration: none;
  color: #000;
}

.profilePictureContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.profilePicture {
  border-radius: 100%;
  height: 150px;
  box-shadow: rgba(46, 41, 51, 0.5) 0px 4px 16px;
}

.mainName {
  margin: 5px 0 0 0;
  text-shadow: rgba(46, 41, 51, 0.5) 0px 4px 16px;
}

.links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-shadow: rgba(46, 41, 51, 0.5) 0px 4px 16px;
}

.links a {
  margin: 1em;
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bolder;
  color: #fff;
  /* color: #4b2e83; */
}

.links a:hover {
  text-decoration: underline;
}

footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  font-size: 0.6em;
  font-style: italic;
}

@media (max-width: 400px) {
  h1 {
    font-size: 12vw;
  }
}