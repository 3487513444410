.portfolioItem {
    background-color: #F8F8F8;
    margin: 10px;
    padding: 10px;
    width: 200px;
    flex-grow: 1;
    position: relative;
    border-radius: 5px;
    box-shadow: rgba(46, 41, 51, 0.2) 0px 4px 6px;
}

.portfolioItemHeaderImage {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: top;
}

.portfolioItemDescription {
    line-height: 1.5em;
    overflow: hidden;
    position: relative;
    height: 4.4em; /* exactly three lines */
}

.portfolioItemDescription:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(248,248,248, 1) 60%);
}

.detailsButton {
    text-align: right;
    position: absolute;
    bottom: 28px;
    right: 10px;
    background: none;
    border: none;
    padding: 0;
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
}

.detailsButton:hover {
    color: #069;
    text-decoration: underline;
}

.placeholder {
    border-top: 14px solid #DDD;
}

.loading button {
    color: #CCC;
}

.loading button:hover {
    color: #CCC;
}