.projectDetailBack {
    display: inline-block;
    background-color: #EAEAEA;
    padding: 10px;
    border-radius: 5px;
}

.projectDetailBack:hover {
    background-color: #E0E0E0;
}

.projectDetailHeaderImage {
    width: 95%;
    object-fit: scale-down;
    margin: 20px 0;
    box-shadow: rgba(46, 41, 51, 0.8) 0px 4px 16px, rgba(71, 63, 79, 0.16) 0px 8px 24px;
    border-radius: 5px;
}

.projectDetail h1, .projectDetail h2 {
    margin: 10px 0;
}

.projectDetail p {
    line-height: 1.5em;
}

.projectDetailDate {
    padding-right: 10px;
    font-size: 0.8em;
    color: #444;
}

.projectDetailTag {
    display: inline-block;
    background-color: #17a2b8;
    color: #fff;
    padding: 5px;
    margin: 0 5px 10px 0;
    border-radius: 2px;
    font-size: 0.8em;
}

.projectDetailLink {
    display: inline-block;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
}

.projectDetailLink:hover {
    background-color: #0069da;
}