.content {
  max-width: 800px;
  margin: auto;
  padding: 20px 20px 40px 20px;
}

@keyframes loadingAnimation {
  0%   { opacity: 0.7; }
  50% { opacity: 0.3; }
  100%   { opacity: 0.7; }
}

.loading {
  opacity: 0.7;
  animation: loadingAnimation 2s infinite;
  color: #CCC;
}

.delay1 {
  animation-delay: 300ms;
}

.delay2 {
  animation-delay: 600ms;
}